import Glide, {Autoplay, Breakpoints, Controls, Swipe} from '@glidejs/glide/dist/glide.modular.esm';

const sliders = document.querySelectorAll('.js-carousel')

for (let i = 0; i < sliders.length; i++) {
	new Glide(sliders[i], {
		autoplay: 6000,
		gap: 24,
		type: 'carousel',
		peek: {
			before: 0,
			after: 0
		},
		perView: 1,
	}).mount({Autoplay, Breakpoints, Controls, Swipe});
}