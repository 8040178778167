initLazyLoad();

function initLazyLoad() {
	let layloadItems;

	function lazyload() {
		layloadItems = document.querySelectorAll(".lazy[data-src]");
		layloadItems.forEach(function (element) {
			if (element.getBoundingClientRect().top + window.scrollY < (window.innerHeight + 600 + window.scrollY)) {
				element.src = element.dataset.src;
				element.classList.remove('lazy');
			}
		});
		if (layloadItems.length === 0) {
			document.removeEventListener("scroll", lazyload);
			window.removeEventListener("resize", lazyload);
			window.removeEventListener("orientationChange", lazyload);
		}
	}

	lazyload()

	document.addEventListener("scroll", lazyload);
	window.addEventListener("resize", lazyload);
	window.addEventListener("orientationChange", lazyload);
}